import request from "@/utils/request";

/**
 * 创建用户【OK】
 * @param params
 * @returns {*}
 */
export function createUser(params) {
  return request({
    url: "/api/backend/account/admin/create",
    method: "post",
    data: params
  });
}
/**
 * 编辑用户【OK】
 * @param params
 * @returns {*}
 */
export function updateUser(params) {
  return request({
    url: "/api/backend/account/admin/update",
    method: "post",
    data: params
  });
}

/**
 * 重置密码【OK】
 * @param params
 * @returns {*}
 */
export function resetPassword(params) {
  return request({
    url: "/api/backend/account/admin/resetPassword",
    method: "post",
    data: params
  });
}

/**
 * 查询账号信息[ok]
 * @param params
 * @returns {*}
 */
export function getBackGroundUserList(params) {
  return request({
    url: "/api/backend/account/admin/getList",
    method: "get",
    params
  });
}

// ============================== vip start ==============================

/**
 * 新建VIP
 * @param params
 * @returns {*}
 */
export function createVipCard(params) {
  return request({
    url: "/api/backend/account/vip/createVipCard",
    method: "post",
    data: params
  });
}

/**
 * 查询VIP列表
 * @param params
 * @returns {*}
 */
export function getUserVipList(params) {
  return request({
    url: "/api/backend/account/vip/getUserVipList",
    method: "get",
    params
  });
}

/**
 * 切换VIP状态
 * @param params
 * @returns {*}
 */
export function switchUserVipStatus(params) {
  return request({
    url: "/api/backend/account/vip/switchUserVipStatus",
    method: "post",
    data: params
  });
}

// ============================== vip start ==============================
